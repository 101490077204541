<template>
    <div>
        <div class="maxwidth">
            <van-field v-model="value" rows="1" autosize type="textarea"></van-field>
            <div class="right">
                <van-button type="primary" @click="contract()">生成合同</van-button>
            </div>

        </div>
        <van-popup v-model="show">
            <div class="div"> 新合同链接：{{HtUrl}}  <br>注：复制上述链接在浏览器打开</div>
        </van-popup>
    </div>
</template>

<script>
import { Editor } from '@/api/detail'
export default {
    data() {
        return {
            show: false,
            HtUrl:"https://sys.ixlzj.com/#/sign",
            value: `1, Room

Host provides Student a single furnished room including desk, desk light, chair, bed, bedclothes, and closet. Host provides clean sheets for the bed during Student's stay.
Host must sustain a comfortable temperature in Student's room while Student is in the house. During cold weather season, the Host is obligated to set Student's room temperature of a minimum 68 Fahrenheit. Student may request host to increase or decrease temperature only if deemed reasonable.
2, Meals

Host provides breakfast and dinner during weekdays and three meals during weekends and non-school days. Host may provide snacks between meals.
Lunch shall be bought by Student at school. HSF may offer additional compensation for providing a packed lunch to Student if the student's party agrees to pay for it. The additional compensation fee for lunch will be dispensed in addition to monthly compensation fee.
Food will be properly prepared by host and made available. It does not need to be served; if Student misses a meal, Host is not responsible to prepare another meal. Leftovers will be available.
3, Internet

Homestay house is high-speed enabled, with DSL, Cable internet or Wifi.
Host may set internet usage rules to prevent the development of negative usage habits on the part of Student.
4, Laundry Facilities

Host allows Student to use laundry machines. Host provides laundry detergent with instructions on how to operate the washer and dryer.
If Host's home uses shared, coin-operated laundry facilities with other units (such as in a condominium or apartment complex), Student needs to pay for the own laundry costs.
5, Rides

Commuting rides to/from schools are agreed to and to be carried out as selected below.
Host provides extra local rides ONLY IF Student pre-schedules. Local rides may include transportation for school events, trips to local store, visits to friend's house, etc.
6, Airport pick-up / drop-off

Host must provide airport pick-up and drop-off at least four round trips per academic year with no charge.
7, School Guardian

Student's Parent(s) agrees to appoint below Host as the guardian of Student for the duration of his/her stay in Host's house.
Host and/or Host's spouse takes on the role of Student's parent(s) for all school matters, such as participating in teacher's conferences, signing in the stead of Student's Parents when forms require parent signatures, school events inviting parent participation, etc.
Host needs to convey issues discussed with teachers or counselors to Student's Parent(s) at least once a month.
8, Payment Confidentiality

Host strictly adheres to solely communicating with HSF regarding the adjustments of fees or expenses for the duration of Student's stay in Host's home.
Host is PROHIBITED from disclosing information regarding the homestay fee to Student in any case. Once the third party (including the students and the parents) knows the homestay fee, host should compensate THREE MONTHS' homestay fee to HSF.
Host should NOT renegotiate the homestay fee and other fees directly with Student's Parent(s) without first discussing them with HSF.
9, Monthly Homestay Fee

HSF will negotiate the monthly homestay fee with host family case by case according to different inquires from students.
Once host family has talked well with HSF about the monthly homestay fee, host family couldn’t change the fee until both parties sign the contract.
If the host family changed the monthly homestay fee after HSF informs that the host family has been chosen by the student, HSF will never cooperate with this host family.
10, Exclusive Rights of the Named Student's Homestay

Host is prohibited to host the named student without contract via HSF for up to three (3) years from the date of signature.
11, Additional International Students

Host is NOT allowed to initiate homestay arrangements for additional international students introduced by Student party.
Host must immediately inform HSF of requests made by Student party for Host to provide homestay services to ANY additional student(s). Homestay arrangements may be arranged ONLY upon the new student's enrollment in HSF programs—exceptions will be provided in writing.
In addition, Host MUST inform HSF if approached by a school or another international agency to host ANY student.
12, Introduction of Hosts

Host is NOT permitted to introduce other hosts to Student unless it is through HSF's host referral process, whereupon Host directs other hosts through the host application process—or provides other host's contact information to HSF so that HSF may provide guidance.
Host is entitled to a referral fee upon HSF's receipt of the signed homestay agreement from the other host.
13， Emergency

Host provides assistance to Student in emergency situations. Student will have his/her own medical insurance. Host does not have any liability regarding the payment of the Student's medical expenses.
Host will sign any urgent and necessary forms in emergency situations, but ALL medical/hospital expenses will be paid by Student's Parent(s) or legal guardian.
14，Student Affairs

If any problem emerges during the student's stay, the host should inform HSF as soon as possible, including but not limited to the student's illness, academic difficulties, communication difficulties, non-compliance with the host family rules, etc.
15，Probation Period

The first Fifteen (15) days are a probationary period for both parties in order to determine whether or not Student and Host family are compatible.
If either party wishes to terminate the homestay, the party in question must apply to HSF during this probationary period.
If a change is requested, HSF will arrange a new homestay for Student. If no termination is requested by either party, this contract remains valid.
16， Renewal of Current Homestay

The homestay arrangement is a month-to-month contract. Host must renew through HSF regarding the homestays of all Student(s) introduced by HSF.
Negotiating directly with Student is strictly prohibited.
17, Absence

Student may be absent due to visitation to his/her home country during school break, hospitalization, or general travel. HSF asks that Host assists Student in updating the HSF coordinators of any plans involving Student's absence from the homestay, as HSF will require completion of a permission slip for extended travel.
Coordinators must be informed of any situation that requires Host to be absent from the homestay overnight.
Regardless of the duration of Student’s absence from homestay, the entire fiscal month's homestay compensation fee will be dispensed to Host, excepting situations where Student is a graduating senior, a transfer student to a boarding school, or Student is departing the homestay with the close of the academic year for the summer.
18，Family Rules

HSF informs Student that he/she will need to follow the Host family's rules as agreed to in his/her contract. Within the first two weeks of Student's arrival, Host MUST inform Student of all family rules.
19， Criminal History/Record

If either Host or the occupants of Host's home have previously committed a crime, Host is obliged to disclose such information to HSF. Results provided from any other party, including those directly from Host, cannot substitute the criminal history background check conducted by HSF immediately after the signing of this agreement.
The results of the criminal history background check will not be disclosed to any third party, even the Student party. Host understands that presence of a record will void this agreement in its entirety.
20, Information Authenticity

The host family must ensure that the information, photos, videos, etc. provided to HSF are true and credible. After informed being chosen as the host family by the student and before signing the contract, the host family should provide HSF with a 15-20S video of the student shared area.
21，Student's Updates

HSF student coordinator or a local coordinator contacts Host to obtain updates on each student’s progress. Host is obligated to respond to their emails/phone calls within 48 hours, to provide any information regarding Student's academic performance; social development; and to notify the coordinator of any issues or provide additional comments on Student.
22, Conflict Mediation

HSF is responsible for creating a sound environment that enables the host and student to communicate. If disagreements, and differences of opinion due to cultural difference escalate into interpersonal conflict, HSF will intervene immediately with conflict resolution mediation.
23, Taxation of Income

Under the guidance of Internal Revenue Service (IRS), any payment to host families is considered as income, and such income should be reported on a 1099 form for tax purposes.
Host agrees to receive the form of 1099-MISC issued by HSF every tax year; and it is Host's sole responsibility for reporting such income to IRS, based on the federal and state (where Host resides and hosts Student) tax laws.
Host MUST provide HSF with his/her valid Social Security Number upon signing this agreement, by filling out the IRS form W-9; and submitting it (the first page only) to HSF along with this agreement.
24, Termination

If Host wishes to terminate the homestay, Host must notify HSF minimum of ONE (1) MONTH in advance so that HSF has enough time to arrange a new Host family for Student.
If termination is requested, Student may stay up to the day before the fiscal day. Host MUST provide the same quality of care and environment to Student for the remaining days after the notification of termination.
25, Indemnification

Host agrees to indemnify and hold harmless HSF and/or its employees, from any and all claims, actions, suits, proceedings, costs, expenses, damages, and liabilities, including attorney's fees arising out of, connected with, or resulting from Host's participation in the HSF program, including but not limited to accepting Student's stay in Host’s home. HSF reserves the right to take legal action for any breach or violation on the host family.`,
        }
    },
    methods: {
        contract() {
            Editor({ data: this.value }).then(res => {
                if (res.code == 200) {
                    this.HtUrl = res.data.url;
                    this.show = true;
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.div   {
    width: 700px;
    height: 200px;
    background: #fff;
    font-size: 20px;
    line-height: 50px;
    color: #ff3131;
    text-align: center;
}
.maxwidth {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    font-size: 20px;

    .right {
        width: 100%;
        text-align: right;
        margin: 20px 0;
    }
}

/deep/.van-field__control {
    font-size: 20px;
}
</style>